

























































import { Component, Vue } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import pagination from "@/components/pagination/index.vue";
import { ExamTime, PageNum } from "@/tool/interface-index";
import * as DashBoardApi from "@/api/examDashboard";
import { ExamProject } from "@/tool/interface-index";
import { getExamProjectList } from "@/api/examProject";
import * as _ from "lodash";

interface TableDataItem extends DashBoardApi.ExamInfoTotalByExamRoom {
  rowId: string,
  hasChildren: boolean,
  children: DashBoardApi.ExamInfoTotalByExamRoom[]
}


@Component({
  name: "ExamInfoTotalByExamSchoolPage",
  components: {
    cardBox,
    pagination
  }
})
export default class extends Vue {
  private query: { keyWords: string, cascadeAreaId: string[], examProjectId: string } = {
    cascadeAreaId: [],
    keyWords: "",
    examProjectId: ""
  };

  private pageNum: PageNum = {
    totalPage: 0,
    curPage: 1,
    pageSize: 10
  };
  private examProjectList: ExamProject[] = [];
  private tableData: Array<TableDataItem> = [];
  getExamDashboardListData() {
    DashBoardApi.getExamInfoTotalByExamSchool(this.query.examProjectId, {
      pageSize: this.pageNum.pageSize,
      curPage: this.pageNum.curPage,
      keyWords: this.query.keyWords,
      schoolId: "",
      areaId: ""
    }).then(({ data }) => {
      console.log(data);
      this.tableData.splice(0, this.tableData.length);
      for (let i = 0; i < data.items.length; i++) {
        const item: DashBoardApi.ExamInfoTotalByExamSchool = data.items[i];
        const row: TableDataItem = _.merge({
          rowId: item.examProjectId + item.schoolId,
          hasChildren: true,
          children: [],
          examRoomId: "",
          examRoomName: ""
        }, item);
        this.tableData.push(row);
      }
      this.pageNum.totalPage = data.totalPage;
    });
  }

  private loadExamRoom(tree: TableDataItem, treeNode: any, resolve: any) {
    DashBoardApi.getExamInfoTotalByExamRoom(tree.examProjectId, {
      schoolId: tree.schoolId
    }).then(({ data }) => {
      if (!data.items.length) {
        resolve([]);
        treeNode.loaded = !treeNode.loaded;
        this.$message("暂无数据");
        return;
      }

      tree.children.splice(0, tree.children.length);

      for (let i = 0; i < data.items.length; i++) {
        const item: DashBoardApi.ExamInfoTotalByExamRoom = data.items[i];
        const row: TableDataItem = _.merge({
          rowId: item.examProjectId + item.examRoomId,
          hasChildren: false,
          children: [],
          examRoomId: item.examRoomId,
          examRoomName: item.examRoomName
        }, item);
        tree.children.push(row);

      }
      resolve(tree.children);
    })
    
  }

  private upDataPage() {
    this.getExamDashboardListData();
  }

  private __init() {
    /*
  getSchoolCascadeList().then(({ data }) => {
    this.schools = data.items;
    this.removeNullChildren(this.schools);
  });
  */
    getExamProjectList({
      examType: "formalExamMode",
      pageSize: 999,
      curPage: 1
    }).then((res) => {
      this.examProjectList = res.data.items;
    });
  }
  private doQueryClick() {
    this.getExamDashboardListData();
  }
  mounted() {
    //this.getExamStudentListData();
    this.__init();
  }

  private async backEndRefresh() {
    for (let i = 0; i < this.tableData.length; i++) {
      const item: TableDataItem = this.tableData[i];

      const { data } = await DashBoardApi.getExamInfoTotalByExamSchool(item.examProjectId, {
        schoolId: item.schoolId
      });
      if (data.items.length <= 0) {
        continue;
      }
      //
      _.merge(item, data.items[0]);
      console.log(`back end refresh school: ${JSON.stringify(item)}`);
      //
      for (let j = 0; j < item.children.length; j++) {
        const { data } = await DashBoardApi.getExamInfoTotalByExamRoom(item.examProjectId, {
          examRoomId: item.children[j].examRoomId
        });
        if (data.items.length <= 0) {
          continue;
        }
        _.merge(item.children[j], data.items[0]);
      }
    }
  }

  private schoolNameFormatter(row: TableDataItem, column: any) {
    return row.examRoomName || row.schoolName

  }

  private refreshInterval: NodeJS.Timeout | undefined = undefined;
  activated() {
    this.getExamDashboardListData();
    this.refreshInterval = setInterval(() => {
      this.backEndRefresh();
    }, 1000 * 60);
  }

  deactivated() {
    clearInterval(this.refreshInterval!);
  }


}
