import request from '@/utils/request'

export interface ExamInfoTotalByExamSchool {
    schoolId: string,
    schoolName: string,
    areaId: string,
    areaName: string,
    examProjectId: string,
    examProjectName: string,
    examTimeCount: number,
    lastExamName: string,
    examStudentCount: number,
    examRecordCount: number,
    examStartedCount: number,
    examEndedCount: number,
    examAnswerPackageCount: number
}

export interface ExamInfoTotalByExamRoom extends ExamInfoTotalByExamSchool {
    examRoomId: string,
    examRoomName: string
}

export interface ExamTimeInfoTotalByExamSchoolAndExamStateTime {
    examSchoolId: string,
    examSchoolName: string,
    examStateYear: number,
    examStateMonth: number,
    examStateDay: number,
    examTimeCount: number;
    examStudentCount: number;
}

/**
 * 考点信息统计
 * @param examProjectId 
 * @param params 
 * @returns 
 */
export const getExamInfoTotalByExamSchool = (examProjectId: string,
    params: {
        pageSize?: number,
        curPage?: number,
        keyWords?: string,
        schoolId?: string,
        areaId?: string
    }) =>
    request({
        url: `/examDashboard/v1/examInfoTotalByExamSchool/${examProjectId}`,
        method: 'GET',
        params
    })

export const getExamInfoTotalByExamRoom = (examProjectId: string,
    params: {
        pageSize?: number,
        curPage?: number,
        keyWords?: string,
        schoolId?: string,
        areaId?: string,
        examRoomId?: string
    }) =>
    request({
        url: `/examDashboard/v1/examInfoTotalByExamRoom/${examProjectId}`,
        method: 'GET',
        params
    })

export const getExamTimeInfoTotalByExamSchoolAndExamStateTime = (
    params: {
        pageSize?: number,
        curPage?: number,
        keyWords?: string,
        schoolId?: string,
        areaId?: string,
        schoolIds: string[],
        examStateTimeBetween: string,
        examStateTimeAnd: string,
        examStateMonthId: string
    }) =>
    request({
        url: `/examDashboard/v1/examTimeInfoTotalByExamSchoolAndExamStateTime`,
        method: 'GET',
        params
    })